import { gql } from "@apollo/client";

/**
 * Update Cart
 *
 * This query is used for both updating the items in the cart and delete a cart item.
 * When the cart item needs to be deleted, we should pass quantity as 0 in the input along with other fields.
 */
const UPDATE_CART = gql`
    mutation ($key: ID!, $quantity: Int!) {
      updateItemQuantities(input: {items: {key:$key, quantity:$quantity}}) {
        cart {
          total
          totalTax
          subtotal
          subtotalTax
          isEmpty
          contentsTax
          contentsTotal
        }
        items {
          key
          quantity
          subtotal
          subtotalTax
          tax
          total
          product {
            node {
              name
              image {
                uri
              }
              shortDescription
              databaseId
            }
          }
        }
      }
    }
`;

export default UPDATE_CART;
