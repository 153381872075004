import { gql } from "@apollo/client";

const REMOVE_PRODUCT = gql`
mutation ($input: RemoveItemsFromCartInput!) {
  removeItemsFromCart(input: $input) {
    cartItems {
      key
      quantity
    }
  }
}
`;
export default REMOVE_PRODUCT;
