import * as React from "react"
import ReactDOM from "react-dom"
import './loading.scss'
import  loadingImg  from "../../images/loading-spinning-bubbles.svg"
function Loading(props) {
  return <div className="loading-container">
            <img className="img" src={loadingImg}/>
          </div>
}
Loading.show = function (props) {
  this.div = document.createElement('div');
  document.body.appendChild(this.div);

  ReactDOM.render(<Loading {...props}/>, this.div);
}
Loading.remove = function () {
  this.div && ReactDOM.unmountComponentAtNode(this.div); 
  this.div && this.div.parentNode.removeChild(this.div); 
}
export default Loading;